@charset "utf-8";

// Google Font
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700|Open+Sans:400,700");

$facebook-background-color: #3b5999;
$github-background-color: #222222;
$linkedin-background-color: #0077b5;
$mail-background-color: #d44638;
$whatsapp-background-color: #25d366;

// Brand colors
$header-purple-secondary: #a9a1fa;
$purple: #786de7;
$light-purple: rgb(117, 107, 235);
$beige-lighter: #eff0eb;

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$family-secondary: "Open Sans", sans-serif;
$primary: $purple;
$title-color: $primary;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Container + columns
$gap: 48px;
$desktop: 1024px + (2 * $gap);
$column-gap: 1.5rem;

// Box
$box-padding-single: 3rem;
$box-padding: 0 $box-padding-single;
$box-radius: 4px;
$box-shadow: 0 15px 35px 0 rgba(50, 50, 93, 0.1),
  0 5px 15px 0 rgba(0, 0, 0, 0.07);

// Image
$dimensions: 16 24 32 48 64 96 128 180;

// Section
$section-padding: 3rem 0;

// Footer
$footer-padding: 3rem 1.5rem;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;

// Animations
$container-animation-duration: 0.6s;
$profile-image-animation-duration: 0.3s;
$container-animation-delay: 0s;

@import "bulma/bulma.sass";

@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/grid/columns.sass";

.header {
  position: absolute;
  top: 0;
  right: 0;
  background-image: linear-gradient(135deg, $light-purple 25%, $header-purple-secondary 100%);
  min-height: 30rem;
  width: 100%;
  z-index: -999;
}

.container-mt {
  margin-top: 14rem;
}

.container-mb {
  margin-bottom: 6rem;
}

.rnd-4px {
  border-radius: 4px;
}

.profile-image {
  position: absolute;
  margin-top: -4rem;

  animation-name: profile-image-entrance;
  -webkit-animation-name: profile-image-entrance;
  animation-duration: $profile-image-animation-duration;
  -webkit-animation-duration: $profile-image-animation-duration;
  animation-delay: ($container-animation-duration + $container-animation-delay);
  -webkit-animation-delay: (
    $container-animation-duration + $container-animation-delay
  );
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

.profile-container {
  margin: 0 auto;
}

.main-box {
  padding-top: 10.25rem !important;
}

.subtitle-negative-mt {
  margin-top: -1.25rem;
}

.separator {
  height: 1px;
  width: 100%;
  background: #e9ecef;
}

.is-centered {
  margin: 0 auto;
}

.about-me {
  margin-top: 2rem;
}

.section {
  padding: 4rem 0;

  .separator {
    margin-bottom: 2.5rem;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  .section-entry {
    padding: 1.5rem 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.level.social-links {
  &.is-desktop {
    position: absolute;
    top: $box-padding-single;
    right: $box-padding-single;
  }

  .social-icon {
    color: $white;
    @extend .rnd-4px;
    height: 2.25rem;
    width: 2.25rem;

    &.facebook {
      background-color: $facebook-background-color;
    }

    &.github {
      background-color: $github-background-color;
    }

    &.linkedin {
      background-color: $linkedin-background-color;
    }

    &.mail {
      background-color: $mail-background-color;
    }

    &.whatsapp {
      background-color: $whatsapp-background-color;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: $white;

      &.facebook {
        background-color: lighten($facebook-background-color, 5%);
      }

      &.github {
        background-color: lighten($github-background-color, 5%);
      }

      &.linkedin {
        background-color: lighten($linkedin-background-color, 5%);
      }

      &.mail {
        background-color: lighten($mail-background-color, 5%);
      }

      &.whatsapp {
        background-color: lighten($whatsapp-background-color, 5%);
      }
    }
  }
}

.skill-icon {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.icon {
  height: auto;
  width: auto;

  .fa-map-marker-alt {
    margin-right: 0.25rem;
  }
}

.neg-box-padding {
  margin: 0 (-$box-padding-single);

  &.footer {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.section.skills {
  ul {
    list-style: none;
    margin: 0;
  }
}

.columns {
  &:not(:last-child) {
    margin-bottom: $column-gap;
  }
}

.column {
  &.icon-column {
    padding: 0.25rem;
  }
}

.content {
  font-family: $family-secondary;
}

.projects {
  .project {
    padding: 0;
    position: relative;
    border-radius: 12px;
    box-shadow: unset;

    &:hover {
      .content {
        opacity: 1;
      }
    }

    figure {
      width: 100%;
      height: 12.5rem;
      position: relative;

      img {
        position: absolute;
        max-height: 100%;
        min-width: 100%;
        height: unset;
        max-width: unset;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .content {
      font-size: 1.125rem;
      opacity: 0;
      background-color: $grey-darker;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.2s;
      color: $white;
      text-align: center;

      p {
        padding: 2rem;
        position: absolute;
        width: 100%;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .container {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .container-mt {
    margin-top: 10rem;
  }

  .box {
    padding: 0 ($box-padding-single/2);
  }

  .neg-box-padding {
    margin: 0 (-$box-padding-single/2);

    &.footer {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.container {
  animation-name: container-entrance;
  -webkit-animation-name: container-entrance;
  animation-duration: $container-animation-duration;
  -webkit-animation-duration: $container-animation-duration;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  animation-delay: $container-animation-delay;
  -webkit-animation-delay: $container-animation-delay;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

.footer {
  padding: $footer-padding;

  .social-links .columns {
    @extend .is-mobile;
  }
}

.border-1px {
  border: 1px solid $beige-lighter;
}

.is-shrinking {
  flex-shrink: 1;
}

// Animations
@keyframes container-entrance {
  0% {
    transform: translateY(8rem);
    -webkit-transform: translateY(8rem);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}

@-webkit-keyframes container-entrance {
  0% {
    transform: translateY(8rem);
    -webkit-transform: translateY(8rem);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}

@keyframes profile-image-entrance {
  0% {
    transform: scaleY(1.2) scaleX(1.2);
    filter: blur(5px);
    opacity: 0;
    box-shadow: 0 1.25rem 2.5rem 0 rgba(50, 50, 93, 0.1),
      0 5px 4px 0 rgba(0, 0, 0, 0.07);
  }
  100% {
    transform: scaleY(1) scaleX(1);
    filter: blur(0);
    box-shadow: 0 1rem 2.25rem 0 rgba(50, 50, 93, 0.1),
      0 5px 4px 0 rgba(0, 0, 0, 0.07);
  }
}

@-webkit-keyframes profile-image-entrance {
  0% {
    transform: scaleY(1.2) scaleX(1.2);
    filter: blur(5px);
    opacity: 0;
    box-shadow: 0 1.25rem 2.5rem 0 rgba(50, 50, 93, 0.1),
      0 5px 4px 0 rgba(0, 0, 0, 0.07);
  }
  100% {
    transform: scaleY(1) scaleX(1);
    filter: blur(0);
    box-shadow: 0 1rem 2.25rem 0 rgba(50, 50, 93, 0.1),
      0 5px 4px 0 rgba(0, 0, 0, 0.07);
  }
}
